@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($element, $transition, $time) {
  -webkit-transition: $element $transition $time;
  -moz-transition: $element $transition $time;
  -ms-transition: $element $transition $time;
  transition: $element $transition $time;
}

@mixin user-select($value) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}
