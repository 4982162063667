$primaryC1: #F29718;
$primaryC2: #E67C00;
$primaryC3: #CC6D00;
$primaryC4: #2C80EB;
$primaryC5: #2871D1;
$primaryC6: #2363B8;

$secondaryC1: #4D9AFF;
$secondaryC2: #338BFF;
$secondaryC3: #2C80EB;
$secondaryC4: #2871D1;
$secondaryC5: #2363B8;

$backgroundC: #E6EEF8;
$bodyC: #8694a3;

$cobalt-blue: #4A79BC;