$breakpoints: (
  xs: ( max-width: 320px ),
  sm: ( min-width: 321px ),
  md: ( min-width: 769px ),
  lg: ( min-width: 1025px )
);

@mixin viewSize($name) {
  @if map-has-key($breakpoints, $name) {
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }
  @else {
    @warn "No value could be retrieved from `#{$name}`. Please make sure it is defined in the `$breakpoints` list.`"
  }
}
