.header-container-home {
  background-color: #ffffff;
  display: block;
  height: 100px;
  position: relative;
  width: 100%;
  .header-logo {
    display: inline-block;
    float: left;
    margin: 34px 0 0 40px;
  }
  .header-text {
    color: $primaryC4;
    display: inline-block;
    float: right;
    font-size: 32px;
    font-family: 'Open Sans', sans-serif;
    margin: 28px 40px 0 0;
  }
}

.header-container {
  background-color: #ffffff;
  display: block;
  height: auto;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.header-content-filler {
  display: block;
  position: relative;
  width: 100%;
}

.header-top-outer-container {
  border-bottom: 2px dashed $backgroundC;
  font-family: 'Open Sans';
  height: 46px;
  overflow: visible;
  width: 100%;
  z-index: 1000;
  .header-top-inner-container {
    background-color: #ffffff;
    display: block;
    height: 44px;
    max-width: 1030px;
    margin: 0 auto;
    overflow: visible;
    padding: 0 15px;
    position: relative;
    width: 100%;
    z-index: 1001;
    .header-language-container {
      background-color: white;
      cursor: pointer;
      display: inline-block;
      float: right;
      height: 44px;
      width: 77px;
      &:hover {
        background-color: $primaryC5;
        // height: 88px;
        .header-language-options {
          position: absolute;
          .header-language-option:not(:first-of-type) {
              display: block;
          }
        }

        .header-language-option {
          color: #ffffff;
        }
      }
      .header-language-options {
        .header-language-option:not(:first-of-type) {
            display: none;
        }
      }
      .header-language-option {
        color: $primaryC5;
        font-size: 12px;
        height: 44px;
        padding: 14px;
        width: 77px;
        .language-flag {
          height: 15px;
          margin: 0 8px 0 0;
          vertical-align: middle;
          width: 22px;
        }
        .language-chevron {
          vertical-align: middle;
        }
      }
      .header-language-option:nth-child(2) {
        background-color: $primaryC5;
        &:hover{
          background-color: $primaryC6;
        }
      }
    }
    .header-link {
      color: $primaryC4;
      float: right;
      font-size: 14px;
      line-height: 44px;
      margin: 0 20px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .header-media-icon {
      background-color: $primaryC4;
      @include border-radius(50%);
      color: #ffffff;
      cursor: pointer;
      display: inline-block;
      float: right;
      font-size: 12px;
      height: 24px;
      margin: 10px 8px 10px 8px;
      padding-top: 6px;
      text-align: center;
      @include transition(all, linear, 0.3s);
      width: 24px;
      &:hover {
        background-color: $primaryC5;
      }
    }
  }
}

.header-bottom-container {
  background-color: #ffffff;
  display: block;
  height: 75px;
  max-width: 1030px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  .header-logo {
    cursor: pointer;
    margin: 12px 0 0 0;
    @include user-select(none);
  }
  .header-bottom-title {
    color: $primaryC4;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    display: block;
    margin: 45px 0 0 -1px;
    position: absolute;
    text-transform: uppercase;
    @include user-select(none);
    z-index: 25;
  }
}

.navigation-outer-container {
  background-color: #ffffff;
  color: $primaryC4;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: auto;
  padding-left: 20px;
  position: relative;
  .navigation-top-level-container-outer {
    display: inline-block;
    float: left;
    height: 75px;
    @include user-select(none);
    width: auto;
    a {
      color:$primaryC4;
    }
    .navigation-top-level-container-inner {
      background-color: #ffffff;
      @include border-radius(20px);
      display: inline-block;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      padding: 5px 5px;
      margin: 22px 0 0 0px;
      @include user-select(none);
      &:hover {
        background-color: $primaryC4;
        color: #ffffff;
      }
    }
  }
  .navigation-top-level-container-outer-active {
    .navigation-top-level-container-inner {
      background-color: $primaryC4;
      color: #ffffff;
      @include border-radius(20px);
      display: inline-block;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      padding: 5px 5px;
      margin: 22px 0 0 10px;
      @include user-select(none);
    }
    .triangle {
      margin-left: calc(50% - 10px);
      margin-top: 14px;
      border-right: 10px transparent solid;
      border-left: 10px transparent solid;
      border-bottom: 10px $primaryC4 solid;
      height: 0;
      width: 0;
    }
  }
  @media (max-width: 789px) {
    display: none;
  }
}

.table-cell-center {
  display: table-cell;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.navigation-top-level-dropdown-container-outer {
  background-color: $primaryC4;
  height: 391px;
  display: none;
  margin: 121px 0 0 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  .navigation-top-level-dropdown-container-inner {
    display: block;
    font-family: 'Open Sans';
    height: 100%;
    padding: 50px 0;
    margin: 0 auto;
    max-width: 1030px;
    .options-chapter {
      background-color: $primaryC6;
      @include border-radius(5px);
      color: #ffffff;
      cursor: pointer;
      display: inline-table;
      float: left;
      font-size: 20px;
      margin: 0 15px;
      height: 100%;
      width: calc(25% - 30px);
      .options-chapter-inner {
        @extend .table-cell-center;
      }
    }
    .options-paragraph {
      background-color: #ffffff;
      @include border-radius(5px);
      color: $primaryC4;
      cursor: pointer;
      display: inline-table;
      float: left;
      font-size: 15px;
      margin: 0 15px 30px 15px;
      width: calc(25% - 30px);
      .options-paragraph-inner {
        @extend .table-cell-center;
        padding: 0 15px;
      }
    }
    .options-paragraph-full {
      height: calc(100%);
    }
    .options-paragraph-half {
      height: calc(50% - 15px);
    }
    .options-paragraph-third {
      height: calc(33% - 20px);
    }
  }
}

.level-1-toggle-template {
  color: #ffffff;
  display: block;
  font-family: 'Open Sans';
  font-size: 18px;
  padding: 20px 15px;
  position: relative;
  width: 100%;
}

.link-chevron {
  float: right;
  margin-top: 3px;
  vertical-align: middle;
}

.navigation-hamburger-container-outer {
  background-color: $primaryC4;
  display: none;
  height: calc(100% - 75px);
  margin-top: 75px;
  overflow-y: scroll;
  padding-bottom: 15px;
  position: fixed;
  width: 100%;
  z-index: 999;
  > a {
    text-decoration: none;
    .other-link {
      @include border-radius(5px);
      color: #ffffff;
      font-family: 'Open Sans';
      font-size: 16px;
      padding: 15px;
      text-decoration: none;
      width: 100%;
    }
  }
  .navigation-hamburger-level-1-container {
    height: auto;
    width: 100%;
    .level-1-toggle {
      @extend .level-1-toggle-template;
      .toggle-chevron {
        @include transition(all, linear, 0.075s);
        float: right;
      }
    }
    .level-1-toggle-active {
      .toggle-chevron {
        float: right;
        -ms-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
    }
  }
  .level-1-expand {
    display: none;
    height: auto;
    padding: 0 15px;
    width: 100%;
    a {
      text-decoration: none;
      .level-1-link {
        background-color: $primaryC6;
        @include border-radius(5px);
        color: #ffffff;
        font-family: 'Open Sans';
        font-size: 14px;
        padding: 15px;
        text-decoration: none;
        width: 100%;
      }
      .level-2-link {
        background-color: #ffffff;
        @include border-radius(5px);
        color: $primaryC4;
        font-family: 'Open Sans';
        font-size: 14px;
        margin-top: 15px;
        padding: 15px;
        text-decoration: none;
        width: 100%;
      }
    }
  }
  .hamburger-social-media-container {
    margin-top: 100px;
    text-align: center;
    width:100%;
    a {
      border: 1px solid #eeeeee;
      @include border-radius(50%);
      color: #ffffff;
      display: inline-block;
      height: 42px;
      font-size: 28px;
      margin: 0 5px;
      padding: 5px;
      text-decoration: none;
      width: 42px;
    }
  }
  .hamburger-language-options-container {
    margin-top: 30px;
    text-align: center;
    width:100%;
    a{
      .language-option {
        border: 1px solid #eeeeee;
        @include border-radius(50%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: inline-block;
        height: 45px;
        font-size: 28px;
        margin: 0 20px;
        text-decoration: none;
        width: 45px;
      }
      .language-option-dutch {
        background-image: url('../img/nl.png');
      }
      .language-option-english {
        background-image: url('../img/en.png');
      }
    }
  }
}

@include viewSize('xs') {

  .header-content-filler {
    height: 75px;
  }

  .header-top-outer-container {
    display: none;
  }
}

@include viewSize('sm') {

  .header-content-filler {
    height: 75px;
  }

  .header-top-outer-container {
    display: none;
  }
}

@include viewSize('md') {

  .header-content-filler {
    height:121px;
  }

  .header-top-outer-container {
    display: block;
  }
}

@include viewSize('lg') {

  .header-content-filler {
    height: 121px;
  }

  .header-top-outer-container {
    display: block;
  }
}
