.content-container-home-outer {
  position: relative;
  background-color: #3885e5;

  .content-container-home-inner {

    .content-home-image {
      display: inline-block;

      max-width: 70%;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
    }
  }
}

.homepage-link-block-wrapper-outer {
  background-color: #3885e5;
  display: block;
  height: auto;
  overflow-y: auto;
  width: 100%;
}

.homepage-link-block-wrapper-inner {
  background-color: #3885e5;
  display: block;
  height: auto;
  margin: 0 auto ;
}

.homepage-link-block-link-container {
  cursor: pointer;
  @include border-radius(5px);
  height: auto;
  overflow-y: auto;
}

.homepage-link-block-link-container-standard {
  background-color: #ffffff;
  .homepage-link-image-container {
    background-position: center center;
    background-repeat: none;
    background-size: cover;
  }
  .homepage-link-text-container {
    color: #3885e5;
  }
}

.homepage-link-block-link-container-blue {
  background-color: #2363B8;
  .homepage-link-image-container {
    img {
      display: block;
      margin: 15% auto;
      max-height: 70%;
      max-width: 80%;
    }
  }
  .homepage-link-text-container {
    color: #ffffff;
  }
}

.homepage-link-block-link-container-orange {
  background-color: #F29718;
  .homepage-link-image-container {
    img {
      display: block;
      margin: 15% auto;
      max-height: 70%;
      max-width: 80%;
    }
  }
  .homepage-link-text-container {
    color: #ffffff;
  }
}

@include viewSize('xs') {
  .homepage-link-block-wrapper-inner {
   width: 100%;
  }

  .homepage-link-block-link-container {
    display: block;
    margin: 10px auto;
    width: 90%;
    .homepage-link-image-container {
      display: inline-block;
      float: left;
      position: relative;
      height: 75px;
      width: 75px;
    }
    .homepage-link-text-container {
      display: inline-block;
      float: left;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 24px;
      padding: 12px 20px 0;
      position: relative;
      height: 75px;
      width: calc(100% - 75px);
      overflow: hidden;
    }
  }
}

@include viewSize('sm') {
  .homepage-link-block-wrapper-inner {
    width: 100%;
  }

  .homepage-link-block-link-container {
    display: block;
    margin: 10px auto;
    width: 90%;
    .homepage-link-image-container {
      display: inline-block;
      float: left;
      position: relative;
      height: 75px;
      width: 75px;
    }
    .homepage-link-text-container {
      display: inline-block;
      float: left;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 75px;
      padding: 0 20px;
      position: relative;
      height: 75px;
      width: calc(100% - 75px);
    }
  }
}

@include viewSize('md') {
  .homepage-link-block-wrapper-inner {
    max-width: 990px;
    width: 100%;
  }

  .homepage-link-block-link-container {
    display: block;
    margin: 10px auto;
    width: 90%;
    .homepage-link-image-container {
      display: inline-block;
      float: left;
      position: relative;
      height: 75px;
      width: 75px;
    }
    .homepage-link-text-container {
      display: inline-block;
      float: left;
      font-family: 'Open Sans', sans-serif;
      font-size: 18px;
      font-weight: bold;
      line-height: 75px;
      padding: 0 20px;
      position: relative;
      height: 75px;
      width: calc(100% - 75px);
    }
  }
}

@include viewSize('lg') {
  .homepage-link-block-wrapper-inner {
    width: 990px;
  }

  .homepage-link-block-link-container {
    display: inline-block;
    height: 227.5px;
    margin: 20px 2.5%;
    @include transition(all, ease, 0.15s);
    vertical-align: middle;
    width: 20%;
    &:hover {
      height: 237.5px;
      margin: 5px 1.5%;
      width: 22%;
      .homepage-link-text-container {
        font-size: 22px;
      }
    }
    .homepage-link-image-container {
      display: block;
      position: relative;
      height: 45%;
      width: 100%;
    }
    .homepage-link-text-container {
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-size: 20px;
      font-weight: bold;
      line-height: normal;
      @include transition(all, ease, 0.15s);
      padding: 24px;
      text-align: center;
      position: relative;
      line-height: normal;
      height: 55%;
      width: 100%;
    }
  }
}
