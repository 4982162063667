table.standard {
  thead, tbody {
    tr {
      td, th {
        padding: 2px;
        p {
          margin: 0;
        }
      }
      td.highlight, th.highlight {
        background-color: #e8f1fd;
        p {
          color: $primaryC4;
        }
      }
      td.notes, th.notes {
        p {
          color: $primaryC4;
        }
      }
      td.single {
        border-bottom: 1px solid $primaryC4;
      }
      td.double {
        border-bottom: 3px double $primaryC4;
      }
      td.dashed {
        border-bottom: 1px dashed $primaryC4;
      }
      th {
        border-bottom: 2px solid $primaryC4;
        p {
          font-family: 'Open Sans';
        }
      }
    }
    tr.total {
      td {
        border-top: 3px solid $primaryC4;
        p {
          font-family: 'Open Sans';
        }
      }
    }
    tr.emphasized {
      td {
        background-color: $primaryC4;
        p {
          color: #ffffff;
          font-family: 'Open Sans';
        }
      }
      td.highlight {
        background-color: $primaryC6;
      }
    }
  }
}
