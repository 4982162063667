body, html {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  background-color: #ffffff;
}

* {
  box-sizing: border-box;
}

@import 'views';
@import 'colors';
@import 'fonts';
@import 'mixins';
@import 'hamburger';
@import 'header';
@import 'footer';
@import 'homepage';
@import 'content';
