@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700');

%header1Font {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 52px;
  line-height: 52px;
  color: $primaryC4;
}

%header2Font {
  font-family: 'Open Sans', sans-serif;
  font-size: 40px;
  line-height: 40px;
  color: $primaryC4;
}

%header3Font {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  line-height: 24px;
  color: $primaryC4;
}

%header4Font {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: $primaryC4;
}

%introFont {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  line-height: 32px;
  color: $primaryC4;
}

%bodyFont {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 32px;
  color: $bodyC;
}

%captionFont {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 20pt;
  color: $bodyC;
}

%quoteFont {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  line-height: 40px;
  color: $primaryC4;
}
