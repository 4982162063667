$hamburger-padding-x                       : 0 !default;
$hamburger-padding-y                       : 25px !default;
$hamburger-layer-width                     : 40px !default;
$hamburger-layer-height                    : 4px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : $primaryC4 !default;
$hamburger-layer-border-radius             : 4px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
$hamburger-hover-use-filter                : false !default;
$hamburger-hover-filter                    : opacity(50%) !default;

$hamburger-types: (
  3dx
);

.hamburger {
  display: inline-block;
  padding: $hamburger-padding-y $hamburger-padding-x;
  cursor: pointer;

  float: right;

  transition-property: opacity, filter;
  transition-duration: $hamburger-hover-transition-duration;
  transition-timing-function: $hamburger-hover-transition-timing-function;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
    @else {
      opacity: $hamburger-hover-opacity;
    }
  }
  
  @media (min-width: 790px) {
    display: none;
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: calc($hamburger-layer-height / -2);

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

@if index($hamburger-types, 3dx) {
  /*
   * 3DX
   */
  .hamburger--3dx {
    .hamburger-box {
      perspective: $hamburger-layer-width * 2;
    }

    .hamburger-inner {
      transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
                  background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);

      &::before,
      &::after {
        transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    &.is-active {
      .hamburger-inner {
        background-color: transparent;
        transform: rotateY(180deg);

        &::before {
          transform: translate3d(0, $hamburger-layer-height + $hamburger-layer-spacing, 0) rotate(45deg);
        }

        &::after {
          transform: translate3d(0, ($hamburger-layer-height + $hamburger-layer-spacing) * -1, 0) rotate(-45deg);
        }
      }
    }
  }
}
