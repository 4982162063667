.content-container-outer {
  display: block;
  position: relative;
  width: 100%;
}

.content-container-inner {
  background-color: #ffffff;
  @include border-radius(5px);
  display: block;
  height: auto;
  margin: -100px auto 0 auto;
  max-width: 890px;
  padding: 15px;
  position: relative;
  width: 100%;
}

.content-top-image {
  display: block;
  margin: 0 auto;
  max-width: 1440px;
  position: relative;
  width: 100%;
}

@include viewSize('xs') {
  .content-container-outer {
    min-height: calc(100% - 50px);
  }

  .content-container-inner {
    margin: 0 auto 0 auto;
  }
}

@include viewSize('sm') {
  .content-container-outer {
    min-height: calc(100% - 60px);
  }

  .content-container-inner {
    margin: 0 auto 0 auto;
  }
}

@include viewSize('md') {
  .content-container-outer {
    min-height: calc(100% - 121px);
  }

  .content-container-inner {
    margin: -100px auto 0 auto;
  }
}

@include viewSize('lg') {
  .content-container-outer {
    min-height: calc(100% - 121px);
  }

  .content-container-inner {
    margin: -100px auto 0 auto;
  }
}

// General Styles
.content-container-inner {
  h1 {
    @extend %header1Font;
    margin-top: 0;
    text-align: center;
  }

  h3 {
    @extend %header2Font;
    text-align: center;
  }

  h4 {
    @extend %header3Font;
    text-align: center;
  }

  h5 {
    @extend %header4Font;
    text-align: center;
  }

  p {
    @extend %bodyFont;
    &.introduction-paragraph {
      color: $primaryC4;
    }
  }

  a {
    color: $primaryC4;
    text-decoration: none;
    &:hover {
      color: $primaryC6;
      text-decoration: underline;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 80%;
    height: auto;
    @include border-radius(5px);
  }

  ol, ul {
    @extend %bodyFont;
  }

  .subparagraph-title {
    border-top: 1px solid #eeeeee;
    font-size: 42px;
    margin-top: 50px;
    padding-top: 25px;
  }

  .subsubparagraph-title {
    border-top: 1px solid #eeeeee;
    font-size: 36px;
    margin-top: 50px;
    padding-top: 25px;
  }

  .highlightblock {
	background-color: #2C80EB;
	padding: 15px 30px;
	p, ul, ol, h2, h3, h4, h5 {
		color: #fff;
	}
}
}

.numberHighlights{
  font-family: 'Open Sans', sans-serif;
  overflow:hidden;
  padding:25px 0;
  max-width:640px;
  margin:25px auto;
  > div {
    float: left;
    width: 50%;

    @media (max-width: 767px) {
      .description{
        p {
          margin-top: 0;
        }
      }
    }
    @media (max-width: 605px) {
      width: 100%;
    }
  }

  p {
    color:black;
  }
}

.numberHighlight{
  height:110px;
  width: 270px;
  margin-bottom:60px;
  background-position: left bottom;
  background-repeat: no-repeat;

  background-size: contain;
/*  margin-right: 30px;*/
  /*width:50%;
  float:left;
  display:inline-block;*/
}

/*.numbericon{
  float:left;
  padding-top:15px;
  padding-right:15px;
  height:100px;
}*/

.numberline{
  text-align:right;
  margin-left: 30px;

  text-align:right;
  white-space:nowrap;
  .number {
    font-size:40pt;
    line-height:36pt;
    color: $cobalt-blue;
    text-align:right;
    font-weight:bold;
  }
  .description{
    font-size:14pt;
    line-height:14pt;
    text-align:right;

  }
}


.accordion {
  padding-left: 0 !important;
  list-style: none;
  border-top: 1px solid #9c9d9f;

  & > li {
    border-bottom: 1px solid #9c9d9f;
    line-height: 1.3;

    & > a {
      position: relative;
      display: block;
      padding: 10px 10px 10px 35px;
      font-size: 18px;
      color: #9c9e9f;/* !important;*/
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 24px;
        height: 24px;
        left: 0;
        top: 9px;
        background: url(../img/collapse-icon.png) 0px 0px no-repeat;
      }

      &:hover {
        color: #2c80eb;
        text-decoration: none;
      }

      &.active {
        color: #2c80eb;

        &:before {
          background-position: 0 -24px;
        }
      }
    }

   > div {
      display: none;
      margin: 10px 0 0 0;
    }
  }
}

p.caption {
  font-style: italic;
  color: #2C80EB;
}
// Tables

@import 'tables'
